import React, { Component } from "react";
import logo from "./placeholder/logo_large.jpg";
import styled from "styled-components";
import facebook from "./placeholder/facebook.svg";
import instagram from "./placeholder/instagram.svg";
import messenger from "./placeholder/messenger.svg";
import email from "./placeholder/email.svg";

class Placeholder extends Component {
  link = (icon, name, url) => {
    return (
      <Icon>
        <a href={url}>
          <img src={icon} height="100%" alt={name} draggable="false" />
        </a>
      </Icon>
    );
  };

  render() {
    return (
      <Container>
        <Centered>
          <LogoLarge>
            <img src={logo} alt="logo_large" width="100%" draggable="false" />
          </LogoLarge>
          <Icons>
            {this.link(facebook, "facebook", "https://www.facebook.com/RETRoSPECT.rsa/")}
            {this.link(instagram, "instagram", "https://www.instagram.com/retrospectsa/")}
            {this.link(messenger, "messenger", "https://m.me/RETRoSPECT.rsa")}
            {this.link(email, "email", "mailto:mariette@retrospect.co.za")}
          </Icons>
        </Centered>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Centered = styled.div`
  position: absolute;
  top: 20%;
  box-sizing: border-box;
  width: 600px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const LogoLarge = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
`;

const Icons = styled.div`
  position: absolute;
  bottom: calc(0% - 20px);
  width: calc(100% - 17%);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    bottom: calc(0% - 0px);
    height: 40px;
  }

  @media only screen and (max-width: 400px) {
    bottom: calc(0% - 0px);
    height: 30px;
  }

  @media only screen and (max-width: 300px) {
    bottom: calc(0% - 0px);
    height: 20px;
  }
`;

const Icon = styled.div`
  position: relative;
  height: calc(100% - 2px);
  margin: 1px 1px;
  color: #3a6932;
  :hover {
    cursor: pointer;
    height: 100%;
    margin: 0px 0px;
  }

  @media (hover: hover) and (pointer: fine) {
    a:hover {
      color: red;
    }
  }
`;

export default Placeholder;
